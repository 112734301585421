import React, { useState, useEffect, useRef } from 'react';
import socketIOClient from 'socket.io-client';

const App = () => {
   const [message, setMessage] = useState('');
   const [receivedMessages, setReceivedMessages] = useState([]);
   const socket = useRef();

   useEffect(() => {
      socket.current = socketIOClient('https://ndisp.io/socket');

      // Listen for the 'message' event from the server
      socket.current.on('message', (message) => {
         setReceivedMessages((prevMessages) => [...prevMessages, message]);
      });

      return () => {
         socket.current.disconnect();
      };
   }, []);

   const handleSubmit = (e) => {
      e.preventDefault();
      socket.current.emit('send_message', message);
      setMessage('');
   };

   return (
      <div className="App">
         <h1>Socket.IO Chat</h1>
         <form onSubmit={handleSubmit}>
            <input
               type="text"
               value={message}
               onChange={(e) => setMessage(e.target.value)}
            />
            <button type="submit">Send</button>
         </form>
         <ul>
            {receivedMessages.map((msg, index) => (
               <li key={index}>{msg}</li>
            ))}
         </ul>
      </div>
   );
};

export default App;
